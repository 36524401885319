<template>
    <div class="row" :style="{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain' }">
        <div class="col-2"></div>
        <div class="col-8">
            <div>
                <p></p>
                <p>
                    <b>State of Being</b> is a Cleveland-based band formed by singer-songwriter Christopher Foldi. Emerging from the alternative and industrial music wave of the early 1990s, the band started with Foldi teaming up with keyboardist Michael Demagall to release their first cassette EP in 1990. As Foldi worked on new demos, he was introduced to Chris Steagall, who contributed expertise that helped shape the band's next wave of songs. This collaboration led to the release of the <i>Function</i> EP in 1993, with the track <i>Virtual Addiction</i> gaining attention in local clubs and on college radio, helping establish the band in the Cleveland music scene. The following year, the band had their live breakthrough, opening for Chemlab at the legendary Phantasy Nite Club.
                </p>
                <p>
                    After some production delays, State of Being's debut full-length CD, <i>Dysfunctional Vision</i> (1995), earned critical praise. <i>Chaos Control</i> described it as "the great missing link between 80s synth-pop and heavy industrial." The band's profile grew further when they performed at WMMS' BuzzardFest, sharing the stage with The Ramones. Despite challenges, including the sudden loss of their drummer just before the gig, the band maintained momentum, thanks to a quick substitution by Steagall on electronic drum pads.
                </p>
                <p>
                    By 1998, with a new lineup, the band shifted towards a heavier, guitar-driven sound, evident in their EP <i>Static in My Brain</i>. Internal struggles nearly led to a breakup in 2000, but they rebounded in 2002 with <i>Implosion</i>, praised by <i>Outburn</i> magazine as "the band's best work yet." Their collaboration with producer Andy Kubiszewski (Stabbing Westward) led to their 2004 album <i>Haywire</i>, which expanded their sound and earned airplay as far as Australia. <i>Haywire</i> blurred the lines between rock, industrial, and electronic, prompting <i>Skope Magazine</i> to describe it as “aggro-indie.”
                </p>
                <p>
                    State of Being was recognized as Cleveland's Best Alternative/Electronic Act for two consecutive years (2004, 2005) by <i>Scene Magazine</i>. Their live shows, supporting acts like Pigface, Thrill Kill Kult, and The Church, further cemented their place in the alt-rock scene.
                </p>
                <p>
                    After a hiatus beginning in 2006, Foldi revived the project with new music in 2016. The album <i>The Misinformation Age</i> reflected the dark, uncertain global climate, with a more subdued electronic sound. In 2018, the follow-up <i>Awake</i> struck a bolder and more optimistic tone. Although live shows were delayed by the COVID-19 pandemic, Foldi continued refining his vision during the downtime.
                </p>
                <p>
                    In 2024, State of Being returned with a revamped lineup featuring Chris Becka on guitar, Greg Boehnlein on bass, Sean Furlong on drums, and Foldi's brother, Scott, on keys. The band's new sound debuted at the Beachland Ballroom, showcasing a reimagined take on material from their early days, bringing fresh energy to classic tracks.
                </p>
                <p>
                    With new music and performances planned, State of Being is poised for another exciting chapter, continuing to evolve and redefine their sound for a new era.
                </p>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import bandImage from '@/assets/band-photo_lt-grey-crop.jpg';

export default defineComponent({
    name: "BioText",
    data() {
    return {
      imageUrl: bandImage
    }
  }
});
</script>
